import React, { useState, useContext } from 'react'
import { View, Text } from 'react-native'
import {
  AuthService,
  ModalComponent,
  PulseOpsService,
  useMobile,
  TextInput,
  AppContext,
  Toast,
  // TransactionType,
  // TaskDetail,
  // TaskDetailService,
  // PulseOpsFormat,
  Alert,
  TaskType,
  GeneralService,
  T0Data,
  TransactionType
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO, Task } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { Color } from '@material-ui/lab'
//import { CashLessForm, CashLessFormBase, mapReciepsUpdatePayload } from '../../request-info'
import _ from 'lodash'
import { VoiceDataGridForm, VoiceRecordingModels, VoiceRecordingServices } from '../../container/voice-recording'

export type CompletePopupProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {
    taskId: string
    processId: string
    updateToCore: boolean
    policyNum: string
    category?: string
    isUWTransaction?: boolean
    UWDecision?: string
    nextStep?: string
    isUWCase?: boolean
    caseId?: string
    isUWQC?: boolean
    isCancelPayment?: boolean
    pvNumberCashout?: string
    transaction?: string
    uwResult?: string
    dynamicKeywordData?: Array<VoiceDataGridForm.DynamicKeywordData>
    isSavedDynamicKeyword?: boolean
    isQC?: boolean,
  }
}

export const CompletePopup = (props: CompletePopupProps) => {
  const { onClose, onSuccess, selectedValue, open } = props
  const { isWide } = useMobile()
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const [comment, setComment] = useState<string>('')
  const [loading, bindLoader] = useLoading(false)

  const { t, i18n } = useTranslation()

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const handleClose = () => {
    setComment('')
    onClose()
  }

  const getUserResultDataForVoiceRecording = () => {
    const submitedData = selectedValue.dynamicKeywordData?.map((item) => ({
      scriptId: item.scriptId || '',
      keywordId: item.keywordId || '',
      result: item.result?.value || '',
      reason: item.reason || '',
      updateKeywordType: item.isGroup ? 'UPDATE_KEYWORD_TYPE_GROUP_EVALUATION' : 'UPDATE_KEYWORD_TYPE_DYNAMIC'
    }))
    const data = {
      resultList: submitedData,
      processInstanceId: selectedValue.processId
    }
    return data
  }

  const complete = () => {
    if (selectedValue.taskId) {
      showGlobalLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastInternal(t('requestInfo:CompleteFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.flatMap((res) =>
          pipe(
            selectedValue.category === TaskType.IDC &&
              selectedValue.dynamicKeywordData &&
              selectedValue.dynamicKeywordData.length > 0 &&
              !selectedValue.isSavedDynamicKeyword ?
              VoiceRecordingServices.submitAIreasonUser(getUserResultDataForVoiceRecording())
              : ZIO.succeed({} as VoiceRecordingModels.ReasonUserC),
            ZIO.flatMap((_) =>
              PulseOpsService.completeTask({
                taskId: selectedValue.taskId,
                data: {
                  author: res.email,
                  message: comment
                },
                updateToCore: selectedValue.updateToCore,
                category: selectedValue.category,
                isUWTransaction: selectedValue.isUWTransaction,
                UWDecision: selectedValue.UWDecision,
                isUWCase: selectedValue.isUWCase,
                nextStep: selectedValue.nextStep,
                isUWQC: selectedValue.isUWQC,
                isCancelPayment: selectedValue.isCancelPayment,
                uwResult: selectedValue.uwResult
              })
            ),
          )
        ),
        ZIO.tap((_) => {
          if (selectedValue.category === TaskType.Claim) {
            pipe(GeneralService.triggerActionClaim(selectedValue.caseId || ''), ZIO.unsafeRun({}))
          }
          showGlobalLoading(false)
          showToast(t('requestInfo:CompleteSuccess'), 'success')
          handleClose()
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          handleClose()
          const status = e?.source?.response?.status
          if (status === 403) {
            return selectedValue.isQC ? Alert.snackbarM(t("message:MS020087"), '', 'error') : Alert.snackbarM(t("message:MS020086"), '', 'error')
          }
          return Alert.snackbarM(e?.source?.message ?? '', 'error')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const getTransactionTypeLabel = (transactionType: string) => {
    const transactionItem = T0Data.getValue(transactionType)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : ''
  }

  return (
    <ModalComponent
      title={selectedValue.isCancelPayment ? t('CashOut:AcceptRemove') : t('claim:Complete')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('claim:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: selectedValue.isCancelPayment ? t('common:Confirm') : t('claim:Complete'),
          type: 'filled',
          disabled: false,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: complete
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <TextInput
          title={selectedValue.isCancelPayment ? t('TaskManagement:Comment') : ''}
          value={comment}
          onChange={setComment}
          placeholder={t('TaskManagement:WriteComment')}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
        {selectedValue.isCancelPayment && (
          <View>
            <Text style={{ paddingTop: 15 }}>{t('CashOut:TextInfoCancel')}</Text>
            <View style={{ flexDirection: 'row', paddingTop: 5, paddingLeft: 5 }}>
              <Text>{'• ' + t('CashOut:TransactionName')}</Text>
              <Text style={{ color: 'red', paddingLeft: 5 }}>{getTransactionTypeLabel(props.selectedValue.transaction ?? '')}</Text>
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 5, paddingLeft: 5 }}>
              <Text>{'• ' + t('CashOut:PolicyNum')}</Text>
              <Text style={{ color: 'red', paddingLeft: 5 }}>{props.selectedValue.policyNum}</Text>
            </View>
            <View style={{ flexDirection: 'row', paddingTop: 5, paddingLeft: 5 }}>
              <Text>{'• ' + t('CashOut:PvNum')}</Text>
              <Text style={{ color: 'red', paddingLeft: 5 }}>{props.selectedValue.pvNumberCashout}</Text>
            </View>
          </View>
        )}
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
