import { ZIO } from '@mxt/zio'
import {
  AppContext,
  // ErrorHandling,
  GeneralService,
  Permission,
  PulseOpsService,
  QCService,
  QCTaskList,
  RBAC,
  SubmissionService,
  SubTasksResponse,
  SubTaskType,
  SupendedTaskList,
  SupplementaryInfoService,
  TabList,
  TaskDetail,
  TaskDetailService,
  TaskType,
  TransactionType,
  useMobile,
  Tab,
  InternalFeedbackService,
  TASK_DETAIL_ROLES,
  UWAllowedTransactionTypeList,
  UnderwrittingService,
  Alert,
  TaskService,
  subscribe,
  SupplementaryInfo,
  SourceType,
  AMLResult,
  ErrorHandling,
  ProductEnum
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import * as React from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, useWindowDimensions } from 'react-native'
import {
  checkRolesForButton,
  checkRolesForButtonAgent,
  isEmptyObject,
  onCompleteAction,
  onEndAction,
  // onManualTransfertActionForQC,
  onRecheckAction,
  onRejectAction,
  onSuspendAction,
  onTransferAction,
  onUnsuspendAction,
  UnsuspendPopup,
  CompletePopup,
  EndPopup,
  RejectPopup,
  SuspendPopup,
  TransferPopup
} from './actions'
import { TaskDetailAction } from './actions/TaskDetailAction'
import { PolicyInfoHeader, StatusList } from './common'
import {
  InternalSuspendScreen,
  QCCheck,
  QCCheckClaim,
  PayoutVerification,
  UnderwrittingScreen,
  VoiceRecordingProvider,
  VoiceRecordingConst,
  VoiceDataGridForm
} from './container'
import {
  Adjudication,
  AllocationForm,
  CalculationForm,
  FinalDecisionForm,
  FinalLetterForm
} from './container/adjudication-info'
import { ClaimScreen } from './container/claim-info'
import { ErrorLogTab } from './container/error-log'
import { GeneralInfoScreen } from './container/generalInfo'
import { GeneralInfoDigitalScreen } from './container/generalInfoClaim'
import { RelatedInfo } from './container/related-info'
import { Status, SupplementaryInfoTab } from './container/supplementary-info'
import { SuspendWriteOffInfo } from './container'
import {
  adjudicationItem,
  CheckBasketName,
  claimItem,
  DetailTabItem,
  notGeneralButton,
  QCTabData,
  QCTabItems,
  tabItems,
  TaskDetailTab,
  getTaskGroupName,
  tabPSDigitalItems,
  tabDSQCtabItems,
  tabDStabItems,
  QCTabAdju,
  QCTabsClaim,
  QCUWTabItems,
  tabDSAgent,
  claimPVItem,
  IDCQCTabItems,
  IDCTabItems
} from './data'
import { InquiryComplaintForm, InquiryComplaintState, RequestInfo, VoiceRecordingRequestInfo } from './request-info'
import { styles } from './styles'
import { TaskDetailStackParamList } from './TaskDetailStackParamList'
import { requestRefreshAllBasketInDashboard, requestRefreshAllInDashboard } from '@pulseops/task/common'
import _ from 'lodash'
import { TaskUnsuspendWriteOffModal } from '../task-dashboard/common'
import { useFocusEffect } from '@react-navigation/core'
import { TaskDetailState } from './state'
import { VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import * as O from 'fp-ts/lib/Option'

type Props = StackScreenProps<TaskDetailStackParamList, 'TaskDetailScreen'>

export const TaskDetailScreen = ({ navigation, route }: Props) => {
  const { isMobile, isWide } = useMobile()
  const [tabIndex, setTabIndex] = React.useState<TaskDetailTab>(() => {
    return route?.params?.caterogy === TaskType.Claim
      ? isClaimPayout
        ? TaskDetailTab.PAYOUT_VER_TAB
        : TaskDetailTab.CLAIM_INFO_TAB
      : TaskDetailTab.REQUEST_INFO_TAB
  })
  const [isOpenStatusButton, setIsOpenStatusButton] = React.useState(false)

  const [completeOpen, setCompleteOpen] = React.useState<boolean>(false)
  const [isEndPopupOpen, setIsEndPopupOpen] = React.useState<boolean>(false)
  const [isRejectPopupOpen, setIsRejectPopupOpen] = React.useState<boolean>(false)
  const [isTransferPopupOpen, setIsTransferPopupOpen] = React.useState<boolean>(false)
  const [isSuspendPopupOpen, setIsSuspendPopupOpen] = React.useState<boolean>(false)
  const [isUnSuspendPopupOpen, setIsUnSuspendPopupOpen] = React.useState<boolean>(false)
  const [isUnsuspendWriteOffModalVisible, setUnsuspendWriteOffModalVisible] = React.useState<boolean>(false)

  const [dataForQCTab, setDataForQCTab] = React.useState<QCTabData>()
  const [dataForQCTabCL, setDataForQCTabCL] = React.useState<QCTabAdju>()
  const [dataForQCAdjuTabCL, setDataForQCAdjuTabCL] = React.useState<QCTabAdju>()

  const [planSuspendDate, setPlanSuspendDate] = React.useState<Date>()
  const [updateToCore, setUpTC] = React.useState<boolean>(false)
  const [UWDecision, setUWDecision] = React.useState<boolean>(false)
  const [UWTransferDecision, setUWTransferDecision] = React.useState<boolean>(false)
  const [oldUWDecisionVal, setOldUWDecisionVal] = React.useState<string>('')
  const [isAcceptUW, setIsAcceptUW] = React.useState<boolean>(false)

  const [isSupActive, setIsSupActive] = React.useState(false)
  const [isInternalSuspendActive, setIsInternalSuspendActive] = React.useState(false)
  const [isAMLDeclineActive, setAMLDeclineActive] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const [isSubmitInquiry, setIsSubmitInquiry] = React.useState<boolean>(false)
  const [isNotCheckValidator, setIsNotCheckValidator] = React.useState<boolean>(false)

  const [detail, setDetail] = React.useState<any>()
  const [clients, setClients] = React.useState<any>()
  const [claimData, setClaimData] = React.useState<any>()
  const [officeName, setOfficeName] = React.useState<string>()
  const [roles, setRoles] = React.useState<string[]>([])
  const [UWDecisionList, setUWDecisionList] = React.useState<UnderwrittingService.UWDecisionInfo[]>([])
  const [isChangeTab, setIsChangeTab] = React.useState<boolean>(false)
  const [updatedIACPayloadData, setUpdatedIACPayloadData] = React.useState<VeriPayloadData>()
  const [dynamicKeywordData, setDynamicKeywordData] = React.useState<Array<VoiceDataGridForm.DynamicKeywordData>>([])
  const [isSavedDynamicKeyword, setIsSavedDynamicKeyword] = React.useState<boolean>(false)
  const [isHiddenButtonsForIACHistory, setIsHiddenButtonsForIACHistory] = React.useState<boolean>(false)

  const [isScroll, setIsScroll] = React.useState<boolean>(false)
  const scrollViewRef = React.useRef<ScrollView>(null)
  const isFocused = useIsFocused()
  const { showToast, changeBreadcrumb, footer, footerClaimActions, showGlobalLoading, getFooter } = React.useContext(
    AppContext.AppContextInstance
  )
  const { t, i18n } = useTranslation('claim')

  // Form
  const inquiryComplaintForm = useForm<InquiryComplaintForm>({
    criteriaMode: 'firstError',
    mode: 'all'
    // shouldUnregister: true
  })
  const { trigger, control } = inquiryComplaintForm
  const { errors } = useFormState({
    control
  })

  const finalDecisionForm = useForm<FinalDecisionForm>({ mode: 'all' })
  const calculationForm = useForm<CalculationForm>()
  const allocationForm = useForm<AllocationForm>()
  const finalLetterForm = useForm<FinalLetterForm>({
    defaultValues: {
      skipReview: false,
      letterContent: ''
    }
  })
  // Call API
  // const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  // const [info, setInfo] = React.useState<{
  //   currentUser: string
  //   historyList: SupplementaryInfo.HistoryList
  // } | null>(null)

  const basket = route?.params?.basket as SubTaskType
  const subTask = route?.params?.basketName as SubTasksResponse
  const caterogy = route?.params?.caterogy as TaskType
  const isCLPayout = route?.params?.isCLPayout
  const subTaskCode = caterogy
  const isClaim = subTask === SubTasksResponse.CL_VER
  const isAdjudication = isCLPayout ? false : [SubTasksResponse.CL_ADJ, SubTasksResponse.CL_SUSPEND].includes(subTask)
  const isClaimPayout = [subTask, isCLPayout].includes(SubTasksResponse.CL_PV)

  let basketType = CheckBasketName.PS
  if (isClaim) {
    basketType = CheckBasketName.CLAIM
  } else if (isAdjudication) {
    basketType = CheckBasketName.ADJUDICATION
  } else if (isClaimPayout) {
    basketType = CheckBasketName.CLAIM_PV
  }

  const isBreadcrumb = route?.params?.isBreadcrumb === '0'
  const isSuspend = basket === SubTaskType.Suspend
  const isInquiry = route?.params?.isInquiry === '1'
  const isDigital = route?.params?.basketName.split('-')[0] === 'CL' || false
  const isQC = isInquiry ? false : basket === SubTaskType.QC || basket === SubTaskType.QCUnderwriting
  const isUWQC = isInquiry ? false : basket === SubTaskType.QCUnderwriting
  const isHistory = route?.params?.isHistory === 'true'
  const isPSDigital = route?.params?.isPSDigital === 'true' ? true : false
  const isUWScreen = basket === SubTaskType.Underwriting
  const [isUWTransaction, setIsUWTransaction] = React.useState<boolean>(false)
  const isPCWriteOff = route?.params?.isPCWriteOff === 'true'

  const isNotEditable = ((isClaim || isAdjudication) && isInquiry) || isSuspend || isQC
  const isEditable = !isNotEditable
  const checkAgent =
    (caterogy === TaskType.DistributionService &&
      detail?.payload.transactionType === TransactionType.AGENT_TERMINATION_AG) ||
    (caterogy === TaskType.DistributionService &&
      detail?.payload.transactionType === TransactionType.AGENT_OFFICE_MOVING) ||
    (caterogy === TaskType.DistributionService &&
      detail?.payload.transactionType === TransactionType.AGENT_UNIT_TRANSFER)
  const checkAgentOnboar =
    (caterogy === TaskType.DistributionService &&
      detail?.payload.transactionType === TransactionType.AGENT_ONBOARD_NEW) ||
    (caterogy === TaskType.DistributionService &&
      detail?.payload.transactionType === TransactionType.AGENT_ONBOARD_REINS)
  const checkAgentPolicy =
    caterogy === TaskType.DistributionService &&
    detail?.payload.transactionType === TransactionType.AGENT_TRANSFER_POLICY

  React.useEffect(() => {
    scrollViewRef.current?.scrollTo({ y: 0, animated: true })
  }, [isScroll])

  React.useEffect(() => {
    !isFocused && setLoading(false)
    return () => {
      setUWDecision(false)
      setUWTransferDecision(false)
      setIsAcceptUW(false)
      setDetail(null)
      setOfficeName(undefined)
      setIsChangeTab(false)
      setUpdatedIACPayloadData(undefined)
      setIsHiddenButtonsForIACHistory(false)

      setDynamicKeywordData([])
      setIsSavedDynamicKeyword(false)
      // console.log('reset state')
    }
  }, [isFocused])

  React.useEffect(() => {
    if (!!roles && roles.length <= 0) {
      pipe(
        RBAC.permissionWithNoCache,
        ZIO.map((userRoles) => {
          setRoles([...userRoles, 'View-Suspend-WriteOffInfo-PC-TaskDetail'])
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [roles])


  // console.log('updatedIACPayloadData in taskdetail:', JSON.stringify(updatedIACPayloadData))

  useFocusEffect(
    React.useCallback(() => {
      setTabIndex(
        route?.params?.caterogy === TaskType.Claim
          ? isClaimPayout
            ? TaskDetailTab.PAYOUT_VER_TAB
            : TaskDetailTab.CLAIM_INFO_TAB
          : TaskDetailTab.REQUEST_INFO_TAB
        // route?.params?.caterogy === TaskType.Claim ? TaskDetailTab.CLAIM_INFO_TAB : TaskDetailTab.INTERNAL_FEEBACK_TAB
      )
      inquiryComplaintForm.reset({
        moneyMissappropriation: {
          list: []
        }
      })
      // setUWDecision(false)
      // setUWTransferDecision(false)
      // setIsAcceptUW(false)
      // setDetail(null)
      // setOfficeName(undefined)
      // setIsChangeTab(false)
      // setUpdatedIACPayloadData(undefined)
      if (route?.params) {
        setLoading(true)
        pipe(
          isInquiry
            ? PulseOpsService.getHistoryDetail(route.params?.id)
            : PulseOpsService.getTaskDetail(route.params?.id || '', route.params?.basketName || ''),
          ZIO.flatMap((x) => {
            return isDigital
              ? pipe(
                TaskDetailService.getDigitalPolicyFull(x.primaryPolicyNo),
                ZIO.map((res) => ({
                  detail: {
                    ...x,
                    policyOwner: res.policy.policyOwnerName
                  },
                  clients: res.clients
                }))
              )
              : x.payload.tag !== TransactionType.VOICE_RECORDING
                ? pipe(
                  SubmissionService.getOwnerInfo(x.primaryPolicyNo ? x.primaryPolicyNo : 'null', isPSDigital),
                  ZIO.map((res) => {
                    return {
                      detail: {
                        ...x,
                        policyOwner: `${res.body?.surName || ''} ${res.body?.firstName || ''}`
                      },
                      clients: undefined
                    }
                  })
                )
                : pipe(
                  SubmissionService.getOwnerInfoVoice(x.primaryPolicyNo && x.primaryPolicyNo !== 'N/A' ? x.primaryPolicyNo : 'null'),
                  ZIO.map((res) => {
                    return {
                      detail: {
                        ...x,
                        policyOwner: `${res.body?.surName || ''} ${res.body?.firstName || ''}`,
                        policyStatus: res.status || ''
                      },
                      clients: undefined
                    }
                  })
                )
          }),
          ZIO.catchAll((error) => {
            setLoading(false)
            return ZIO.fail(error)
          }),
          ZIO.tap((data) =>
            caterogy === TaskType.GENERAL
              ? ZIO.unit
              : pipe(
                SupplementaryInfoService.getHistoryListAndUsers(data.detail.processId),
                ZIO.map((susData) => {
                  const isSupplementarySuspend = susData.historyList.data.some(
                    ({ status }) => status === Status.ACTIVE
                  )
                  setIsSupActive(isSupplementarySuspend)
                  return isSupplementarySuspend
                }),
                ZIO.tap((isSupplementarySuspend) => {
                  return isSupplementarySuspend || caterogy === TaskType.IDC
                    ? ZIO.unit
                    : pipe(
                      InternalFeedbackService.getInternalSuspendHistoryList(data.detail.caseId),
                      ZIO.tap((historySuspendList) => {
                        if (!!historySuspendList && historySuspendList.length > 0) {
                          setIsInternalSuspendActive(
                            historySuspendList?.some((historyItem) => historyItem.status === Status.ACTIVE)
                          )
                        } else {
                          setIsInternalSuspendActive(false)
                        }
                        return ZIO.unit
                      })
                    )
                })
              )
          ),
          ZIO.tap((value) => {
            setLoading(false)
            setUpTC(value.detail.updateToCore)
            const { detail, clients } = value
            setAMLDeclineActive(
              detail.amlResult === AMLResult.HIT_DECLINE || detail.unsuspendTypeAML === 'AML_RED_FLAG_DECLINE'
            )
            setDetail(detail)
            setClients(clients)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
    }, [route?.params])
  )

  React.useEffect(() => {
    if (
      basketType === CheckBasketName.CLAIM ||
      basketType === CheckBasketName.ADJUDICATION ||
      (caterogy === TaskType.Claim && basket === SubTaskType.QC)
    ) {
      pipe(
        ZIO.zipPar(
          GeneralService.getAccidentName(),
          GeneralService.getSubClaimType(),
          GeneralService.getSubClaimTypeBenefit(),
          GeneralService.getTotalPermanentDisability(''),
          GeneralService.getCritialIllness(),
          GeneralService.getICDName(),
          GeneralService.getHospitals()
        ),
        ZIO.map(([accidents, claimTypes, subClaimTypes, tpdTypes, ciTypes, icdTypes, hospitals]) => {
          const data = {
            accidents,
            claimTypes,
            subClaimTypes,
            tpdTypes,
            ciTypes,
            icdTypes,
            hospitals,
            id: route.params.id
          }
          setClaimData(data)
        }),
        ZIO.unsafeRun({})
      )
    } else {
      setClaimData(undefined)
    }
  }, [basketType])

  React.useEffect(() => {
    setTabIndex(
      caterogy === TaskType.Claim
        ? isClaimPayout
          ? TaskDetailTab.PAYOUT_VER_TAB
          : TaskDetailTab.CLAIM_INFO_TAB
        : caterogy === TaskType.GENERAL
          ? TaskDetailTab.RELATED_INFO_TAB
          : TaskDetailTab.REQUEST_INFO_TAB
    )
    // setTabIndex(caterogy === TaskType.Claim ? TaskDetailTab.CLAIM_INFO_TAB : TaskDetailTab.INTERNAL_FEEBACK_TAB)
  }, [caterogy])

  React.useEffect(() => {
    getDataForQCTab()
    console.log(detail, 'detail')
    if (detail) {
      const officeInfoCode = detail?.officeCode

      if (!!officeInfoCode && !officeName) {
        getOfficeNameByCode(officeInfoCode)
      }
    }
    if (isFocused && !route.params.isChangeBreadrum) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.replace('HomeScreen')
          }
        },
        isBreadcrumb
          ? {
            title: t('home:TaskManagement'),
            navigate: () =>
              navigation.navigate('UserDrawer', {
                screen: 'TaskDashboardScreen'
              })
          }
          : {
            title: t('menu:ServiceInquiry'),
            navigate: () =>
              navigation.navigate('UserDrawer', {
                screen: 'ServiceInquiryScreen'
              })
          },
        { title: detail ? detail?.caseId : '-', navigate: null }
      ])
    }
  }, [detail])

  React.useEffect(() => showGlobalLoading(loading), [loading])

  React.useEffect(() => {
    if (detail && detail.processId) {
      setOldUWDecisionVal('')
      if (detail?.payload?.tag === TransactionType.CHANGE_CLIENT_INFO && subTask === SubTasksResponse.PS_VER) {
        setUWDecision(true)
      }
      pipe(
        caterogy === TaskType.PolicyService
          ? UnderwrittingService.checkUWTransactiontype(detail?.payload?.tag as TransactionType, detail.source ?? '')
          : ZIO.succeed(false),
        ZIO.flatMap((isUWTransaction) => {
          setIsUWTransaction(isUWTransaction)
          return pipe(
            ZIO.zipPar(
              isUWTransaction || isUWScreen ? UnderwrittingService.getUWDecisionList() : ZIO.succeed([]),
              isUWTransaction ? UnderwrittingService.getUWClientDecisionList(detail.processId) : ZIO.succeed([])
            )
          )
        }),
        ZIO.map(([decisionArr, clientDecisions]) => {
          // let topPriority = -1
          let topUWDecisionCode: string = ''
          setUWDecisionList(decisionArr)
          if (clientDecisions && clientDecisions.length > 0) {
            const finalUWLevel = clientDecisions.reduce((topUWLevel, item) => {
              const UWPriority =
                item && item.uwDecision
                  ? decisionArr.find((x) => x.codeUWDecision === item.uwDecision)?.priority ?? -1
                  : -1
              return topUWLevel === 0 || topUWLevel < UWPriority ? UWPriority : topUWLevel
            }, 0)
            const decisionItem = decisionArr.find((x) => x.priority === finalUWLevel)
            topUWDecisionCode = decisionItem?.codeUWDecision ?? ''
            setOldUWDecisionVal(topUWDecisionCode)
            if (topUWDecisionCode === 'DC-ACCEPT-AT-EXISTING') {
              setUWDecision(true)
            } else if (topUWDecisionCode === 'DC-TRANSFER') {
              setUWTransferDecision(true)
            }
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [detail])

  const onHandleActionSuccess = (mustRefreshTaskGroup = false) => {
    if (mustRefreshTaskGroup) {
      requestRefreshAllInDashboard()
    } else {
      requestRefreshAllBasketInDashboard()
    }

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('UserDrawer', {
        screen: 'TaskDashboardScreen'
      })
    }
  }

  const getPolicyOwnerHeaderName = React.useMemo(() => {
    if (detail) {
      const nameWrap = [detail?.clientNumber, detail?.policyOwner]
        .filter((f): f is String => !!f.trim() && f !== 'null')
        .join('-')
      return !!nameWrap ? nameWrap : '-'
    } else {
      return '-'
    }
  }, [detail])

  const getOfficeNameByCode = (officeCode: string) => {
    if (officeCode) {
      pipe(
        GeneralService.getOfficeCode(officeCode),
        ZIO.map((officeItem) => {
          let officeItemName = '',
            officeNameLanguage = ''
          if (officeItem && officeItem.body && officeItem.body.length > 0) {
            officeNameLanguage = i18n.language === 'vi' ? officeItem.body[0].nameVi : officeItem.body[0].nameEn
            officeItemName = officeItem.body[0].code + ' - ' + officeNameLanguage

            setOfficeName(officeItemName)
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const menus = React.useMemo(() => {
    const primaryPolicy =
      detail?.primaryPolicyNo && detail?.primaryPolicyNo !== 'null' && !!detail?.primaryPolicyNo.trim()
        ? detail.primaryPolicyNo
        : null

    const proposalNumber = !!detail?.payload?.proposalNumber?.trim()
      ? detail!.payload!.proposalNumber
      : !!detail?.payload?.proposalNumberOld?.trim()
        ? detail!.payload!.proposalNumberOld
        : null

    const customOfficeName =
      detail &&
        [SourceType.PRUONLINE, SourceType.ZALO, SourceType.PRUDAILY].includes(detail?.payload?.source as SourceType)
        ? 'CUS'
        : officeName

    return [
      {
        label: t('common:PolicyNumber'),
        value: primaryPolicy ?? proposalNumber ?? '-'
      },
      {
        label: t('common:PolicyOwner'),
        value: getPolicyOwnerHeaderName
      },
      {
        label: t('common:Office'),
        value: customOfficeName ?? '-'
      }
    ]
  }, [detail, officeName, getPolicyOwnerHeaderName])

  const menusds = [
    {
      label: t('DistributionService:eREF'),
      value: detail?.payload?.eREF ?? '-'
    },
    {
      label: t('DistributionService:CaseID'),
      value: detail?.caseId ?? '-'
    },
    {
      label: t('DistributionService:IDNumber'),
      value: detail?.clientNumber ?? '-'
    }
  ]

  const menuIDC = React.useMemo(() => {
    const primaryPolicy = pipe(
      detail?.primaryPolicyNo,
      O.fromNullable,
      O.fold(
        () => null,
        (policyData) => {
          const primaryPo = policyData !== 'null' && policyData !== 'N/A' &&
            !!policyData.trim() ? policyData : null
          return primaryPo
        }
      )
    )

    const proposalNumber = !!detail?.payload?.proposalNumber?.trim()
      ? detail!.payload!.proposalNumber
      : !!detail?.payload?.proposalNumberOld?.trim()
        ? detail!.payload!.proposalNumberOld
        : null
    const chanel = detail && detail?.payload ? detail?.payload.saleChannel : '-'
    const policyOwnerName =
      detail && !!detail?.policyOwner && !!detail?.policyOwner.trim()
        ? detail?.policyOwner
        : detail?.payload && detail?.payload.policyOwnerName
          ? detail?.payload.policyOwnerName
          : ''
    return [
      {
        label: t('Outbound:OBTaskManagement:Channel'),
        value: chanel
      },
      {
        label: t('common:PolicyNumber'),
        value: primaryPolicy ?? proposalNumber ?? '-'
      },
      {
        label: t('common:PolicyOwner'),
        value: policyOwnerName
      }
    ]
  }, [detail])

  const getTaskDetailHeader = () => {
    const headerComponent = checkAgentOnboar ? menusds : caterogy === TaskType.IDC ? menuIDC : menus
    return headerComponent
  }

  const checkInternalSuspendListForActionSuspendButton = () => {
    pipe(
      InternalFeedbackService.getInternalSuspendHistoryList(detail.caseId),
      ZIO.tap((historyList) => {
        if (!!historyList && historyList.length > 0) {
          setIsInternalSuspendActive(historyList?.some((historyItem) => historyItem.status === Status.ACTIVE))
        } else {
          setIsInternalSuspendActive(false)
        }
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const menusAgent = [
    {
      label: t('DistributionService:AgentNumber'),
      value: detail?.payload?.structure?.payload?.body?.agent?.agentCode ?? '-'
    },
    {
      label: t('DistributionService:AgentName'),
      value: detail?.payload?.structure?.payload?.body?.agent?.agentName ?? '-'
    },
    {
      label: t('DistributionService:OfficeCode'),
      value: detail?.payload?.structure?.payload?.body?.agent?.officeCode ?? '-'
    }
  ]
  // Call to Action

  const onChangeTab = (tabIdx: number) => {
    setTabIndex(tabIdx)
    setIsChangeTab(true)
    tabIdx === 3 && !isSupActive && checkInternalSuspendListForActionSuspendButton()
    getFooter(<></>)
  }

  const onPressStatusButton = () => {
    setIsOpenStatusButton((prevState) => !prevState)
  }

  // const updateSuppendHistory = () => {
  //   return pipe(
  //     SupplementaryInfoService.getHistoryListAndUsers(detail.processId),
  //     ZIO.map((res) => {
  //       setInfo(res)
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  // }

  const onPressSuspend = (detail: TaskDetail.Type) => {

    if (caterogy === TaskType.DistributionService) {
      showGlobalLoading(true)
      pipe(
        SupplementaryInfoService.checkExpireDate(detail.processId),
        ZIO.foldM(
          (error) => {
            showGlobalLoading(false)
            return ZIO.fail(error)
          },
          (success) => {
            showGlobalLoading(false)
            setPlanSuspendDate(moment(success.expiredDate, 'DD/MM/yyyy HH:mm:ss').toDate())
            onSuspendAction(showToast, t)(detail, isSubmitInquiry)(setIsSuspendPopupOpen, setIsNotCheckValidator)()
            return ZIO.succeed(success)
          }
        ),
        ZIO.unsafeRun({})
      )
    } else if (caterogy === TaskType.IDC) {
      let isValidData: boolean = true
      if (dynamicKeywordData && dynamicKeywordData.length > 0) {
        if (dynamicKeywordData.some((x) => x.isChangedData)) {
          showToast(t('message:MS020063'), 'error')
          isValidData = false
        } else if (!dynamicKeywordData.some((x) => x.result?.value !== 'pass')) {
          showToast(t('message:MS020062'), 'error')
          isValidData = false
        }
      }
      if (isValidData) {
        showGlobalLoading(true)
        pipe(
          SubmissionService.getPolicy(detail.policyNumber),
          ZIO.foldM(
            () => ZIO.succeed(false),
            (policyInfo) => {
              if (policyInfo.body.status !== ProductEnum.Status.PS) {
                showToast(t('message:MS020059'), 'error')
                return ZIO.succeed(false)
              } else {
                return ZIO.succeed(true)
              }
            }
          ),
          ZIO.flatMap((isValidPolicyStatus) => isValidPolicyStatus ?
            SupplementaryInfoService.checkExpireDate(detail.processId) :
            ZIO.succeed(undefined)
          ),
          ZIO.foldM(
            (error) => {
              showGlobalLoading(false)
              return ZIO.fail(error)
            },
            (success) => {
              showGlobalLoading(false)
              if (success) {
                setPlanSuspendDate(moment(success.expiredDate, 'DD/MM/yyyy HH:mm:ss').toDate())
                onSuspendAction(showToast, t)(detail, isSubmitInquiry)(setIsSuspendPopupOpen, setIsNotCheckValidator)()
              }
              return ZIO.succeed(success)
            }
          ),
          ZIO.unsafeRun({})
        )
      }
    } else {
      showGlobalLoading(true)
      pipe(
        InternalFeedbackService.getInternalSuspendExpiredDate(detail.processId),
        ZIO.foldM(
          (err) => {
            return pipe(
              SupplementaryInfoService.checkExpireDate(detail.processId),
              ZIO.catchAll(() => {
                showGlobalLoading(false)
                return ZIO.fail(null)
              }),
              ZIO.tap(({ expiredDate }) => {
                showGlobalLoading(false)
                setPlanSuspendDate(
                  moment(
                    caterogy === TaskType.PremiumCollection
                      ? moment(expiredDate, 'DD/MM/yyyy HH:mm:ss').diff(moment().add(60, 'days'), 'days') > -1
                        ? moment().add(60, 'days')
                        : expiredDate
                      : expiredDate,
                    'DD/MM/yyyy HH:mm:ss'
                  ).toDate()
                )
                onSuspendAction(showToast, t)(detail, isSubmitInquiry)(setIsSuspendPopupOpen, setIsNotCheckValidator)()
                return ZIO.unit
              })
            )
          },
          (success) => {
            showGlobalLoading(false)
            setPlanSuspendDate(moment(success, 'DD/MM/yyyy HH:mm:ss').toDate())
            onSuspendAction(showToast, t)(detail, isSubmitInquiry)(setIsSuspendPopupOpen, setIsNotCheckValidator)()
            return ZIO.succeed(success)
          }
        ),
        ZIO.unsafeRun({})
      )
    }
    // updateSuppendHistory()
  }

  const onCompleteTaskAction = (isFormInquiryValid: boolean) =>
    onCompleteAction(showGlobalLoading, showToast, t)(
      subTask,
      subTaskCode,
      detail,
      isSubmitInquiry,
      trigger,
      isFormInquiryValid,
      dynamicKeywordData
    )(setCompleteOpen, setIsNotCheckValidator)

  const onRejectTaskAction = (isFormInquiryValid: boolean) =>
    onRejectAction(showToast, t)(detail, isSubmitInquiry, dynamicKeywordData)(
      setIsRejectPopupOpen,
      setIsNotCheckValidator,
      trigger,
      isFormInquiryValid
    )

  const onCompleteWrapper = (type: string) => () => {
    if (
      [
        TransactionType.INQUIRY,
        TransactionType.COMPLAINT,
        TransactionType.COMPLAINT_AND_INQUIRY,
        TransactionType.AGENT_ONBOARD_NEW,
        TransactionType.AGENT_ONBOARD_REINS
        // TransactionType.AGENT_TERMINATION
      ].includes(detail.payload?.tag)
    ) {
      if (type === 'complete') {
        trigger().then((value) => {
          onCompleteTaskAction(value)()
        })
      } else if (type === 'reject') {
        trigger().then((value) => {
          onRejectTaskAction(value)()
        })
      }
    } else {
      if (type === 'complete') {
        if (
          oldUWDecisionVal &&
          (updateToCore ||
            [
              TransactionType.HEALTH_DECLARATION,
              TransactionType.RIDER_ALTERATION,
              TransactionType.MAJOR_COMBINED_CHANGE
            ].includes(detail?.payload.tag)) &&
          !UWTransferDecision &&
          !['DC-ACCEPT-AT-EXISTING', 'DC-TRANSFER'].includes(oldUWDecisionVal)
        ) {
          // show message alert
          Alert.modal({
            title: 'Warning',
            content: t('message:MS020022', { DecisionName: oldUWDecisionVal }),
            size: {
              width: '30%'
            },
            onOK: () => {
              setIsAcceptUW(true)
              onCompleteTaskAction(false)()
            },
            onClose: () => {
              setIsAcceptUW(false)
            }
          })
        }
        // else if (
        //   !updateToCore &&
        //   !!oldUWDecisionVal &&
        //   !UWTransferDecision &&
        //   oldUWDecisionVal !== 'DC-TRANSFER' &&
        //   ![
        //     TransactionType.HEALTH_DECLARATION,
        //     TransactionType.RIDER_ALTERATION,
        //     TransactionType.MAJOR_COMBINED_CHANGE
        //   ].includes(detail?.payload.tag)
        // ) {
        //   Alert.snackbar(t('Update to core must be checked'), 'Error', 'error')
        // }
        else {
          onCompleteTaskAction(false)()
        }
      } else if (type === 'reject') {
        onRejectTaskAction(false)()
      }
    }
  }

  const checkJournal = () => {
    if (
      detail &&
      detail.payload &&
      (detail.payload?.tag === TransactionType.JOURNAL_ALTERATION ||
        detail.payload?.tag === TransactionType.JOURNAL_PAYOUT)
    )
      return true
    return false
  }

  const getActionList = () => {
    //cashout remove
    if (subTask && subTask === SubTasksResponse.PS_VER && detail && detail.isCancelPayment) {
      return [
        {
          name: t('CashOut:AcceptRemove'), // Accept
          action: detail
            ? onCompleteAction(showGlobalLoading, showToast, t)(
              subTask,
              subTaskCode,
              detail,
              isSubmitInquiry,
              trigger,
              isEmptyObject(errors)
            )(setCompleteOpen, setIsNotCheckValidator)
            : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('Complete')
        },
        {
          name: t('CashOut:RejectRemove'),
          action: detail ? () => onPressSuspend(detail) : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('Suspend')
        },
        {
          name: t('End'),
          action: detail
            ? onEndAction(showToast, t)(detail, isSubmitInquiry)(setIsEndPopupOpen, setIsNotCheckValidator)
            : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('End') && !isUWScreen
        }
      ]
    }

    if (!subTask || !notGeneralButton.includes(subTask)) {
      return [
        {
          name: t('Complete'),
          action: detail ? onCompleteWrapper('complete') : () => null,
          isVisible:
            checkRolesForButton(subTaskCode, roles, isQC)('Complete') &&
            !isSupActive &&
            !isInternalSuspendActive &&
            !isUWScreen &&
            !isAMLDeclineActive
        },
        {
          name: t('Transfer'),
          action: detail
            ? onTransferAction(showToast, t)(detail, isSubmitInquiry)(setIsTransferPopupOpen, setIsNotCheckValidator)
            : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('Transfer') && !isUWScreen
        },
        {
          name: t('Suspend'),
          action: detail ? () => onPressSuspend(detail) : () => null,
          isVisible:
            checkRolesForButton(subTaskCode, roles, isQC)('Suspend') &&
            (isSupActive || isInternalSuspendActive) &&
            !isUWScreen &&
            !checkJournal() &&
            !isAMLDeclineActive
        },
        {
          name: t('Reject'),
          action: detail ? onCompleteWrapper('reject') : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('Reject') && !isUWScreen
        },
        {
          name: t('End'),
          action: detail
            ? onEndAction(showToast, t)(detail, isSubmitInquiry)(setIsEndPopupOpen, setIsNotCheckValidator)
            : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('End') && !isUWScreen
        }
      ]
    }
    if (subTask && SupendedTaskList.includes(subTask)) {
      const suspendCode =
        caterogy === TaskType.PremiumCollection ? (detail && detail.payload.rawData.suspendCode) || '' : ''
      if (suspendCode.includes('AML05') || (suspendCode.includes('AML06') && caterogy === TaskType.PremiumCollection)) {
        return []
      }

      return [
        isPCWriteOff
          ? {
            name: t('TaskManagement:UnSuspendWriteOff'),
            action: detail?.payload.writeOffAction ? onUnsuspendAction(setUnsuspendWriteOffModalVisible) : () => null,
            isSubmissionDisabled: !detail?.payload.writeOffAction
          }
          : {
            name: t('Unsuspend'),
            action: detail ? onUnsuspendAction(setIsUnSuspendPopupOpen) : () => null
          }
      ]
    }

    if (subTask && QCTaskList.includes(subTask)) {
      return [
        {
          name: t('Complete'), // Accept
          action: detail
            ? onCompleteAction(showGlobalLoading, showToast, t)(
              subTask,
              subTaskCode,
              detail,
              isSubmitInquiry,
              trigger,
              isEmptyObject(errors)
            )(setCompleteOpen, setIsNotCheckValidator)
            : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('Accept')
        },
        {
          name: t('Transfer'), // Decline
          action:
            detail && dataForQCTab
              ? onRecheckAction(showGlobalLoading, showToast, t)(
                detail,
                dataForQCTab.mistakes,
                undefined,
                isUWQC && isUWTransaction,
                detail?.uwResult
              )(onHandleActionSuccess)
              : () => null,
          isVisible: checkRolesForButton(subTaskCode, roles, isQC)('Decline')
        },
        {
          name: t('TransferQC'),
          action: detail ? () => setIsTransferPopupOpen(true) : () => null,
          isVisible:
            checkRolesForButton(subTaskCode, roles, isQC)('Decline') &&
            [TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService, TaskType.IDC].includes(subTaskCode)
        }
      ]
    }

    return []
  }

  const getDataForQCUW = () => {
    const isQCUW = basket === SubTaskType.QCUnderwriting
    if (isQCUW) {
      pipe(
        TaskService.getUserGroup(detail.processId),
        ZIO.flatMap((userGroup) =>
          userGroup && userGroup.split('-')?.length > 0
            ? ZIO.zipPar(
              QCService.getQCUWSave(detail.processId),
              GeneralService.getErrorMistake('PS', userGroup.split('-')[1], true)
            )
            : ZIO.fail(null)
        ),
        ZIO.catchAll((_error) => {
          setDataForQCTab(undefined)
          return ZIO.fail(null)
        }),
        ZIO.tap(([history, data]) => {
          const totalScore = history.detailList.reduce(
            (total, item) => (item.qcCheck ? total : total + item.minusScore),
            0
          )
          const QC = _.sortBy(data, 'qcCode').reduce(
            (pre, cur) =>
              pre.concat(
                cur.errors.map((item) => ({
                  ...item,
                  errorId: item.error_id,
                  minusScore: item.score,
                  qcCheck:
                    (history?.detailList || []).length > 0
                      ? (history?.detailList || []).find((x) => x.errorId === item.error_id)?.qcCheck || false
                      : false
                }))
              ),
            [] as any
          )
          setDataForQCTab({
            lastUser: history.lastUser || '',
            totalScore: `${totalScore}`,
            remark: history.remark || '',
            mistakes: QC || []
          })
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getActionListAgent = () => {
    if (!subTask || !notGeneralButton.includes(subTask)) {
      return [
        {
          name: t('End'),
          action: detail
            ? onEndAction(showToast, t)(detail, isSubmitInquiry)(setIsEndPopupOpen, setIsNotCheckValidator)
            : () => null,
          isVisible: checkRolesForButtonAgent(subTaskCode, roles)
        }
      ]
    }
    return []
  }
  const getDataForQCClaim = () => {
    const isQC = basket === SubTaskType.QC
    if (isQC) {
      pipe(
        ZIO.zipPar(
          GeneralService.getHistoryQCScore(detail.caseId, 'Administrator'),
          GeneralService.getErrorMistake('CL', 'Administrator')
        ),
        ZIO.catchAll((_error) => {
          return ZIO.fail(null)
        }),
        ZIO.tap(([history, data]) => {
          const maxMinusScore = data?.reduce((total, item) => total + (item.maximumTopicMinusScore as number), 0)
          const QC = _.sortBy(data, 'qcCode').reduce(
            (pre, cur) =>
              pre.concat(
                cur.errors.map((item) => ({
                  ...item,
                  topic: i18n.language === 'vi' ? cur.topicVN : cur.topic,
                  minusScore: cur.maximumTopicMinusScore,
                  qcCheck:
                    (history?.qcScoreDetailTopics || []).length > 0
                      ? (history?.qcScoreDetailTopics || []).find((x) => x.errorId === item.error_id)?.qcCheck || false
                      : false
                }))
              ),
            [] as any
          )
          setDataForQCTabCL({
            lastUser: history.lastUser || '',
            totalScore: `${history.totalScore !== 'NA' ? parseInt(history.totalScore as string) : maxMinusScore}`,
            maxMinusScore: maxMinusScore,
            remark: history.remark === 'NA' ? '' : history.remark,
            mistakes: QC || []
          })
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    } else {
      pipe(
        ZIO.zipPar(
          GeneralService.getHistoryQCScore(detail.caseId, 'Administrator'),
          GeneralService.getErrorMistake('CL', 'Administrator'),
          GeneralService.getHistoryQCScore(detail.caseId, 'Adjudicator'),
          GeneralService.getErrorMistake('CL', 'Adjudicator')
        ),
        ZIO.catchAll((_error) => {
          return ZIO.fail(null)
        }),
        ZIO.tap(([historyQC, dataQC, historyAdju, dataAdju]) => {
          const QCAdju = dataAdju.find((_) => _.topic === null)
          const totalScore = QCAdju?.errors.reduce(
            (total, item) =>
              (historyAdju?.qcScoreDetailTopics || []).find((x) => x.errorId === item.error_id)?.qcCheck
                ? total
                : total + item.score,
            0
          )

          setDataForQCAdjuTabCL({
            lastUser: historyAdju.lastUser || '',
            totalScore: `${totalScore}`,
            remark: historyAdju.remark === 'NA' ? '' : historyAdju.remark,
            mistakes:
              QCAdju?.errors.map((_) => ({
                ..._,
                topic: '',
                minusScore: 0,
                qcCheck:
                  (historyAdju?.qcScoreDetailTopics || []).length > 0
                    ? (historyAdju?.qcScoreDetailTopics || []).find((x) => x.errorId === _.error_id)?.qcCheck || false
                    : false
              })) || []
          })

          const maxMinusScore = dataQC?.reduce((total, item) => total + (item.maximumTopicMinusScore as number), 0)
          const QC = _.sortBy(dataQC, 'qcCode').reduce(
            (pre, cur) =>
              pre.concat(
                cur.errors.map((item) => ({
                  ...item,
                  topic: i18n.language === 'vi' ? cur.topicVN : cur.topic,
                  minusScore: cur.maximumTopicMinusScore,
                  qcCheck:
                    (historyQC?.qcScoreDetailTopics || []).length > 0
                      ? (historyQC?.qcScoreDetailTopics || []).find((x) => x.errorId === item.error_id)?.qcCheck ||
                      false
                      : false
                }))
              ),
            [] as any
          )
          setDataForQCTabCL({
            lastUser: historyQC.lastUser || '',
            totalScore: `${historyQC.totalScore !== 'NA' ? parseInt(historyQC.totalScore as string) : maxMinusScore}`,
            maxMinusScore: maxMinusScore,
            remark: historyQC.remark === 'NA' ? '' : historyQC.remark,
            mistakes: QC || []
          })
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getDataForQCTab = () => {
    if (detail) {
      if (
        caterogy === TaskType.Claim &&
        [SubTaskType.QC, SubTaskType.Suspend, SubTaskType.Adjudication].includes(basket)
      ) {
        getDataForQCClaim()
      } else if ([SubTaskType.QCUnderwriting].includes(basket)) {
        getDataForQCUW()
      } else if ([SubTaskType.QC].includes(basket)) {
        pipe(
          QCService.getQCSave(detail.processId),
          ZIO.catchAll((_error) => {
            return ZIO.fail(null)
          }),
          ZIO.tap((saveData) => {
            const totalScore = saveData.detailList.reduce(
              (total, item) => (item.qcCheck ? total : total + item.minusScore),
              0
            )
            setDataForQCTab({
              lastUser: saveData.lastUser || '',
              totalScore: `${totalScore}`,
              remark: saveData.remark || '',
              mistakes: saveData.detailList.sort((a, b) => Number(a.errorId) - Number(b.errorId))
            })
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
    } else if (
      caterogy === TaskType.Claim &&
      detail &&
      (basket === SubTaskType.Adjudication || route.params.basket.toString() === '')
    ) {
      getDataForQCClaim()
    } else {
      setDataForQCTab(undefined)
    }
  }

  const onSubmitInquiry = (isSubmit: boolean) => {
    setIsSubmitInquiry(isSubmit)
  }

  const onCheckValidator = (isValidator: boolean) => {
    setIsNotCheckValidator(isValidator)
  }

  let isHideUpdateToCore = false
  switch (detail?.payload?.tag) {
    case TransactionType.INQUIRY:
    case TransactionType.COMPLAINT:
    case TransactionType.COMPLAINT_AND_INQUIRY:
    case TransactionType.COMMON_REQUEST_FORM:
    case TransactionType.POLICY_CERTIFICATE:
    case TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT:
    case TransactionType.SCHEDULED_PAYMENT_STATEMENT:
    case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE:
    case TransactionType.LOAN_STATEMENTS:
    // case TransactionType.BILLING_FREQUENCY_REVERSAL:
    case TransactionType.FATCA_DECLARATION:
    case TransactionType.HEALTH_DECLARATION:
    case TransactionType.SIGNATURE_REGISTRATION:
    case TransactionType.CHANGE_PREMIUM:
    case TransactionType.CHANGE_SUM_ASSURED:
    case TransactionType.RIDER_ALTERATION:
    case TransactionType.MAJOR_COMBINED_CHANGE:
    case TransactionType.VOICE_RECORDING:
      isHideUpdateToCore = true
      break

    default:
      break
  }

  const getTransactionDisabledUTC = () => {
    switch (detail?.payload?.tag) {
      // case TransactionType.FULL_SURRENDER_REVERSAL:
      case TransactionType.PARTIAL_SURRENDER:
        return true
      default:
        return false
    }
  }

  // const handleSaveLog = () => {
  //   if (isQC) {
  //     return ZIO.unit
  //   } else {
  //     const transactionType = detail?.payload.tag
  //     if (
  //       detail &&
  //       (transactionType === TransactionType.CASHLESS_O1 ||
  //         transactionType === TransactionType.CASHLESS_O2 ||
  //         transactionType === TransactionType.CASHLESS_O3)
  //     ) {
  //       return pipe(
  //         ZIO.fromPromise(async () => (await cashLessForm?.base.trigger()) ?? false),
  //         ZIO.flatMap((result) => {
  //           if (result) {
  //             const validated = cashLessForm?.base.getValues() as CashLessForm.Validated

  //             const totalMoney = validated.receips.reduce<number>(
  //               (pre, cur) =>
  //                 cur.info.reduce<number>(
  //                   (p, c) => Number(PulseOpsFormat.thousandSepartorReverse(c.amount ?? '0')) + p,
  //                   0
  //                 ) + pre,
  //               0
  //             )

  //             if (totalMoney > validated.amount) {
  //               return ZIO.fail(Throwable('Tổng tiền hoá đơn lớn hơn số tiền đã thanh toán'))
  //             } else {
  //               const detailWrapper = detail?.payload as unknown as TaskDetail.VeriCashLess
  //               const dataUpdate = mapReciepsUpdatePayload(validated, detailWrapper)
  //               const payloadUpdate = {
  //                 ...detailWrapper?.rawData.payload,
  //                 body: {
  //                   ...detailWrapper?.rawData.payload.body,
  //                   ...dataUpdate
  //                 }
  //               }
  //               return TaskDetailService.saveLog(detail?.id ?? '', payloadUpdate, detail?.processId ?? '', [])
  //             }
  //           } else {
  //             return ZIO.fail(Throwable(_.get(cashLessForm, 'formState.errors')?.message ?? ''))
  //           }
  //         })
  //       )
  //     } else {
  //       return ZIO.unit
  //     }
  //   }
  // }

  const hasTab = (tabId: TaskDetailTab, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }
  const getListTabs = (): Tab[] => {
    if (roles.length > 0) {
      let listTab: DetailTabItem[] = []
      if (basketType === CheckBasketName.CLAIM) {
        listTab = claimItem
      } else if (basketType === CheckBasketName.ADJUDICATION) {
        listTab = adjudicationItem
      } else if (basketType === CheckBasketName.CLAIM_PV) {
        listTab = claimPVItem
      } else if (caterogy === TaskType.DistributionService) {
        if (checkAgent || checkAgentPolicy) {
          listTab = tabDSAgent
        } else {
          if (basket === SubTaskType.QC) {
            listTab = tabDSQCtabItems
          } else {
            listTab = tabDStabItems
          }
        }
      } else if (caterogy === TaskType.IDC) {
        listTab = basket === SubTaskType.QC ? IDCQCTabItems : IDCTabItems
      } else if (basket === SubTaskType.QC) {
        caterogy === TaskType.Claim ? (listTab = QCTabsClaim) : (listTab = QCTabItems)
      } else if (basket === SubTaskType.QCUnderwriting) {
        listTab = QCUWTabItems
      } else if (isPSDigital) {
        listTab = tabPSDigitalItems
      } else {
        listTab = tabItems.filter(
          (item) =>
            item.id !== TaskDetailTab.INTERNAL_FEEBACK_TAB ||
            (item.id === TaskDetailTab.INTERNAL_FEEBACK_TAB &&
              [TaskType.Complaint, TaskType.CustomerService, TaskType.Claim].includes(caterogy))
        )
      }
      const taskGroup = getTaskGroupName(caterogy)

      const visibleTabs: Tab[] = listTab
        .filter((item) => {
          const tabPermission = taskGroup ? item.permission[taskGroup] : ''
          const subTabPermissions = [...item.subTabPermissions.values()]
          const isRequestTabPermission = !_.isEmpty(item.permission)
          const isRequestSubTabPermission = subTabPermissions.length > 0
          const hasTabPermission = tabPermission && roles.includes(tabPermission)
          const hasSubTabPermission = roles.filter((role) => subTabPermissions.includes(role)).length > 0
          const isNoNeedPermission = !isRequestTabPermission && !isRequestSubTabPermission

          if (item.id === TaskDetailTab.GENERAL_INFO_TAB && caterogy === TaskType.GENERAL) {
            return false
          }

          if (isNoNeedPermission) {
            return true
          }

          if (isRequestTabPermission && isRequestSubTabPermission) {
            return hasTabPermission && hasSubTabPermission
          }

          if (isRequestTabPermission) {
            return hasTabPermission
          }

          if (isRequestSubTabPermission) {
            return hasSubTabPermission
          }

          return false
        })
        .map((item) => ({ id: item.id, title: item.title }))

      if (visibleTabs.length > 0 && !hasTab(tabIndex, visibleTabs)) {
        setTabIndex(visibleTabs[0].id)
      }
      console.log('visibleTabs:', visibleTabs)
      return visibleTabs
    }
    return []
  }

  const tabs = getListTabs()

  const getUWDecision = () => {
    return UWTransferDecision ? 'DC-TRANSFER' : UWDecision || isAcceptUW ? 'ACCEPT' : ''
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: useMobile().isMobile ? '#E5E5E5' : '#F9F9F9',
        paddingTop: 16
      }}
    >
      <ScrollView ref={scrollViewRef}>
        <View style={{ flex: 1 }}>
          {checkAgent ? <PolicyInfoHeader menus={menusAgent} /> : <PolicyInfoHeader menus={getTaskDetailHeader()} />}
          <View style={{ marginHorizontal: 15 }}>
            <TabList
              isScrollIcon={checkAgent || checkAgentPolicy ? false : true}
              menus={tabs}
              isSubTab={false}
              useOriginalId={true}
              tabIndex={tabIndex}
              onChangeTab={onChangeTab}
            />
          </View>
          {detail && (
            <View style={[styles.container, styles.relative, isWide && { backgroundColor: '#fff' }, { flex: 1 }]}>
              {/* Genaral Info Tab for PS*/}
              {hasTab(TaskDetailTab.GENERAL_INFO_TAB, tabs) &&
                [TaskDetailTab.GENERAL_INFO_TAB].includes(tabIndex) &&
                caterogy !== TaskType.GENERAL && (
                  <GeneralInfoScreen
                    navigation={navigation}
                    policyNum={
                      caterogy === 'PC'
                        ? detail.payload.policyNumber ?? detail.primaryPolicyNo
                        : caterogy === 'IDC'
                          ? detail.policyNumber || ''
                          : detail.primaryPolicyNo
                    }
                    roles={roles}
                    isGeneral={true}
                    caterogy={caterogy}
                  />
                )}

              {/* Genaral Info Tab for CL-VER, CL-ADJ*/}
              {hasTab(TaskDetailTab.CLAIM_GENERAL_INFO_TAB, tabs) &&
                [TaskDetailTab.CLAIM_GENERAL_INFO_TAB].includes(tabIndex) &&
                !isPSDigital && (
                  <GeneralInfoDigitalScreen
                    isEditable={isEditable}
                    roles={roles}
                    navigation={navigation}
                    policyNum={detail.primaryPolicyNo}
                    isGeneral={true}
                  />
                )}

              {/* Genaral Info Tab for PS DIGITAL*/}
              {hasTab(TaskDetailTab.CLAIM_GENERAL_INFO_TAB, tabs) &&
                [TaskDetailTab.CLAIM_GENERAL_INFO_TAB].includes(tabIndex) &&
                isPSDigital && (
                  <GeneralInfoDigitalScreen
                    isEditable={isEditable}
                    roles={roles}
                    navigation={navigation}
                    policyNum={detail.primaryPolicyNo}
                    isGeneral={true}
                  />
                )}
              {/* Request Info Tab for PS or Claim Info for CL-VER, CL-ADJ */}
              {(hasTab(TaskDetailTab.REQUEST_INFO_TAB, tabs) || hasTab(TaskDetailTab.CLAIM_INFO_TAB, tabs)) &&
                [TaskDetailTab.REQUEST_INFO_TAB, TaskDetailTab.CLAIM_INFO_TAB].includes(tabIndex) && (
                  <View
                    style={[
                      styles.tabContainer,
                      {
                        flex: 1,
                        paddingBottom: TaskDetailTab.CLAIM_INFO_TAB ? 60 : 0
                      }
                    ]}
                  >
                    {tabIndex === TaskDetailTab.CLAIM_INFO_TAB && clients && claimData ? (
                      <ClaimScreen
                        isEditable={isEditable}
                        roles={roles}
                        detail={detail}
                        isClaim={isClaim}
                        claimData={claimData as any}
                        clients={clients}
                        subtask={subTask}
                        disabled={isSuspend}
                        navigation={navigation}
                        route={{
                          id: route.params.id,
                          basketName: route.params.basketName
                        }}
                        isInquiry={isInquiry}
                      />
                    ) : caterogy === TaskType.IDC ? (
                      <VoiceRecordingProvider>
                        <VoiceRecordingRequestInfo
                          detail={detail}
                          subTask={subTask}
                          isHistory={isHistory}
                          isInquiry={isInquiry}
                          policyStatus={detail?.policyStatus || ''}
                          positionPlayer={0}
                          setDynamicKeywordData={setDynamicKeywordData}
                          setIsSavedDynamicKeyword={setIsSavedDynamicKeyword}
                        />
                      </VoiceRecordingProvider>
                    ) : (
                      <RequestInfo
                        detail={detail}
                        basket={basket}
                        category={caterogy}
                        updateToCore={updateToCore}
                        subTask={subTask}
                        inquiryComplaintForm={inquiryComplaintForm}
                        onSubmitInquiry={onSubmitInquiry}
                        roles={roles}
                        isNotCheckValidator={isNotCheckValidator}
                        onCheckValidator={onCheckValidator}
                        isHistory={isHistory}
                        isInquiry={isInquiry}
                        UWData={{
                          isUWTransaction: isUWTransaction,
                          UWDecision: getUWDecision(),
                          isUWCase: isUWScreen
                        }}
                        setUpdatedIACPayloadData={setUpdatedIACPayloadData}
                        updatedIACPayloadData={updatedIACPayloadData as VeriPayloadData}
                        isChangeTab={isChangeTab}
                        setIsScroll={setIsScroll}
                        isScroll={isScroll}
                      // historyList={info?.historyList}
                      />
                    )}
                  </View>
                )}
              {/* underwritting tab */}
              {hasTab(TaskDetailTab.UNDERWRITTING_TAB, tabs) &&
                [TaskDetailTab.UNDERWRITTING_TAB].includes(tabIndex) &&
                [TaskType.PolicyService, TaskType.Underwriting].includes(caterogy) && (
                  <UnderwrittingScreen
                    taskId={detail.id}
                    veriPayloadData={detail}
                    policyNum={detail.primaryPolicyNo}
                    processInstanceID={detail.processId}
                    caseId={detail.caseId}
                    transactionType={detail?.payload?.tag}
                    updateToCore={updateToCore}
                    category={caterogy}
                    basket={basket}
                    disabled={!isUWScreen}
                    isSupActive={isSupActive}
                    UWDecisionTypeArr={UWDecisionList}
                    onHandleActionSuccess={onHandleActionSuccess}
                  ></UnderwrittingScreen>
                )}
              {/* Internal feedBack tab */}
              {hasTab(TaskDetailTab.INTERNAL_FEEBACK_TAB, tabs) &&
                [TaskDetailTab.INTERNAL_FEEBACK_TAB].includes(tabIndex) &&
                [TaskType.Complaint, TaskType.CustomerService, TaskType.Claim].includes(caterogy) && (
                  <InternalSuspendScreen
                    category={caterogy}
                    processInstanceId={detail.processId}
                    businessKey={detail.caseId}
                    disabled={
                      isInquiry || isSuspend || isQC || !roles.includes(TASK_DETAIL_ROLES.EDIT_INTERNAL_SUSPEND)
                    }
                  ></InternalSuspendScreen>
                )}

              {/* Adjudication Tab for CL-ADJ */}
              {hasTab(TaskDetailTab.CLAIM_ADJU_TAB, tabs) && [TaskDetailTab.CLAIM_ADJU_TAB].includes(tabIndex) && (
                <View style={[styles.tabContainer, { paddingBottom: 60, flex: 1 }]}>
                  <Adjudication
                    route={{
                      id: route.params.id,
                      basketName: route.params.basketName,
                      officeCode: detail?.officeCode
                    }}
                    subTask={subTask}
                    subTaskCode={subTaskCode}
                    navigation={navigation}
                    detail={detail}
                    processId={detail.processId}
                    finalDecisionForm={finalDecisionForm}
                    calculationForm={calculationForm}
                    allocationForm={allocationForm}
                    finalLetterForm={finalLetterForm}
                    isSupActive={isSupActive}
                    setIsUnSuspendPopupOpen={setIsUnSuspendPopupOpen}
                    disabled={isSuspend || !isEditable}
                    roles={roles}
                    isInquiry={isInquiry}
                    onChangeTab={setTabIndex}
                    claimData={claimData as any}
                  />
                </View>
              )}

              {/* Related Info Tab for PC, PS, CL-VER, CL-ADJ*/}
              {(roles.includes(Permission.ViewRelatedInfoTaskDetail) ||
                roles.includes(Permission.ViewRelatedInfoPC) ||
                roles.includes(Permission.ViewRelatedInfoIDCTaskDetail)) &&
                hasTab(TaskDetailTab.RELATED_INFO_TAB, tabs) &&
                [TaskDetailTab.RELATED_INFO_TAB].includes(tabIndex) && (
                  <RelatedInfo
                    processId={detail.processId}
                    taskId={detail.id}
                    policyNum={
                      caterogy === 'IDC' ? detail.policyNumber ?? detail.payload.proposalNumber : detail.primaryPolicyNo
                    }
                    roles={roles}
                    isInquiry={isInquiry}
                    isSuspend={isSuspend}
                    isQC={isQC}
                    disabled={!isEditable}
                    category={route?.params?.caterogy ?? ''}
                    p40ProcessInstanceId={detail.p40ProcessInstanceId}
                  />
                )}

              {hasTab(TaskDetailTab.QC_CHECK_TAB_CLAIM, tabs) &&
                [TaskDetailTab.QC_CHECK_TAB_CLAIM].includes(tabIndex) &&
                dataForQCAdjuTabCL && (
                  <View style={[styles.tabContainer, { flex: 1 }]}>
                    <QCCheckClaim
                      data={dataForQCAdjuTabCL}
                      processId={detail.processId}
                      onDataChange={setDataForQCAdjuTabCL}
                      subTask={subTask}
                      detail={{
                        caseId: detail.caseId,
                        policyNumber: detail.policyNumber,
                        policyOwner: detail.policyOwner,
                        clientNumber: detail.clientNumber
                      }}
                      roles={roles}
                    />
                  </View>
                )}

              {roles.includes(Permission.ViewRelatedInfoTaskDetail) &&
                hasTab(TaskDetailTab.PAYOUT_VER_TAB, tabs) &&
                [TaskDetailTab.PAYOUT_VER_TAB].includes(tabIndex) && (
                  <PayoutVerification
                    detail={detail}
                    clients={clients}
                    navigation={navigation}
                    subTask={subTask}
                    setIsUnSuspendPopupOpen={setIsUnSuspendPopupOpen}
                    roles={roles}
                  />
                )}
              {/* Error Log Tab for PS */}
              {(roles.includes(Permission.ViewWorkflowCommentTaskDetail) ||
                roles.includes(Permission.ViewErrorlogPC)) &&
                hasTab(TaskDetailTab.ERROR_LOG_TAB, tabs) &&
                [TaskDetailTab.ERROR_LOG_TAB].includes(tabIndex) && (
                  <View style={[styles.tabContainer, { paddingBottom: 60, flex: 1 }]}>
                    <ErrorLogTab
                      processId={detail.processId}
                      category={caterogy}
                      p40ProcessInstanceId={detail.p40ProcessInstanceId}
                    >
                    </ErrorLogTab>
                  </View>
                )}

              {/* Supplementaty Tab for PS, CL-ADJ */}
              {hasTab(TaskDetailTab.SUPPLEMENT_INFO_TAB, tabs) &&
                [TaskDetailTab.SUPPLEMENT_INFO_TAB].includes(tabIndex) && (
                  <View style={[styles.tabContainer, { marginBottom: 0, flex: 1 }]}>
                    <SupplementaryInfoTab
                      policyNum={detail.primaryPolicyNo}
                      processId={detail.processId}
                      caseId={detail.caseId}
                      roles={roles}
                      readonly={isInquiry || isSuspend || isQC}
                      active={{
                        value: isSupActive,
                        setSupIsActive: setIsSupActive
                      }}
                      isClaim={isClaim || isAdjudication}
                      disable={!isEditable}
                      category={route?.params?.caterogy ?? ''}
                      officeCode={detail?.officeCode}
                      writeOffAction={detail?.payload.writeOffAction && !isPCWriteOff ? true : false}
                      // navigation={navigation}
                      onHandleActionSuccess={onHandleActionSuccess}
                      transaction={detail?.payload?.tag}
                      detail={detail}
                      claimData={{ ...claimData } as any}
                      firstSuspendDate={detail?.payload?.firstSuspendDate}
                    />
                  </View>
                )}
              {/* Suspend Write-off Info Tab for PC*/}
              {hasTab(TaskDetailTab.SUSPEND_WRITE_OFF, tabs) &&
                [TaskDetailTab.SUSPEND_WRITE_OFF].includes(tabIndex) &&
                caterogy === TaskType.PremiumCollection && (
                  <View style={[styles.tabContainer, { marginBottom: 0, flex: 1 }]}>
                    <SuspendWriteOffInfo
                      // policyNum={detail.primaryPolicyNo}
                      processId={detail.processId}
                    // roles={roles}
                    // readonly={isInquiry || isSuspend || isQC}
                    // active={{
                    //   value: isSupActive,
                    //   setSupIsActive: setIsSupActive
                    // }}
                    // isClaim={isClaim || isAdjudication}
                    // disable={!isEditable}
                    // category={route?.params?.caterogy ?? ''}
                    // officeCode={detail?.officeCode}
                    />
                  </View>
                )}
              {/* QC check Tab for QC basket */}
              {(hasTab(TaskDetailTab.QC_CHECK_TAB, tabs) || hasTab(TaskDetailTab.QC_UW_CHECK_TAB, tabs)) &&
                ([TaskDetailTab.QC_CHECK_TAB].includes(tabIndex) ||
                  [TaskDetailTab.QC_UW_CHECK_TAB].includes(tabIndex)) &&
                (dataForQCTab || dataForQCTabCL) && (
                  <View style={[styles.tabContainer, { flex: 1 }]}>
                    <QCCheck
                      data={dataForQCTab as QCTabData}
                      dataClaim={dataForQCTabCL}
                      processId={detail.processId}
                      onDataChange={setDataForQCTab}
                      onDataCLChange={setDataForQCTabCL}
                      isClaim={caterogy === TaskType.Claim}
                      navigation={navigation}
                      detail={{ ...detail, subTask, subTaskCode, caterogy, basket }}
                      roles={roles}
                      isUWQC={isUWQC}
                    />
                  </View>
                )}
            </View>
          )}
        </View>
      </ScrollView>
      {isMobile && <StatusList visible={isOpenStatusButton} onSelect={onPressStatusButton} />}

      {hasTab(TaskDetailTab.REQUEST_INFO_TAB, tabs) &&
        (tabIndex === TaskDetailTab.REQUEST_INFO_TAB ||
          ([TaskType.CustomerService, TaskType.PolicyService, TaskType.Complaint, TaskType.IDC].includes(caterogy) &&
            [
              TaskDetailTab.GENERAL_INFO_TAB,
              TaskDetailTab.SUPPLEMENT_INFO_TAB,
              TaskDetailTab.RELATED_INFO_TAB,
              TaskDetailTab.QC_CHECK_TAB,
              TaskDetailTab.INTERNAL_FEEBACK_TAB,
              TaskDetailTab.QC_UW_CHECK_TAB
            ].includes(tabIndex))) &&
        !isInquiry &&
        !isHiddenButtonsForIACHistory && (
          <TaskDetailAction
            disableUTC={
              [SubTaskType.QC, SubTaskType.Suspend].includes(basket) ||
              detail?.payload.tag === TransactionType.FULL_SURRENDER_REVERSAL
            }
            listAction={checkAgent || checkAgentPolicy ? getActionListAgent() : getActionList()}
            // const caterogy = route?.params?.caterogy as TaskType
            caterogy={caterogy}
            UWDecision={{
              UWAcceptVal: UWDecision,
              setAcceptUWVal: setUWDecision,
              disabledAcceptUW: !!oldUWDecisionVal,
              UWTransferVal: UWTransferDecision,
              setTransferUWVal: setUWTransferDecision,
              isShowedTransferButton: !!oldUWDecisionVal,
              disabledTransferUW: isUWScreen || ['DC-ACCEPT-AT-EXISTING', 'DC-TRANSFER'].includes(oldUWDecisionVal)
            }}
            isUWScreen={isUWScreen}
            isUWTransaction={isUWTransaction}
            updateToCore={
              !isHideUpdateToCore
                ? {
                  val: updateToCore,
                  setVal: setUpTC
                }
                : undefined
            }
            amlResult={detail?.amlResult}
            redFlagResult={
              caterogy === TaskType.PremiumCollection &&
                detail?.redFlagResult === 'N' &&
                detail?.payload?.stpFunction === 'MS990091'
                ? 'ERROR'
                : detail?.redFlagResult
            }
          />
        )}

      {(hasTab(TaskDetailTab.CLAIM_INFO_TAB, tabs) ||
        hasTab(TaskDetailTab.CLAIM_ADJU_TAB, tabs) ||
        hasTab(TaskDetailTab.PAYOUT_VER_TAB, tabs)) &&
        [
          TaskDetailTab.CLAIM_INFO_TAB,
          TaskDetailTab.CLAIM_ADJU_TAB,
          TaskDetailTab.QC_CHECK_TAB_CLAIM,
          TaskDetailTab.QC_CHECK_TAB,
          TaskDetailTab.PAYOUT_VER_TAB
        ].includes(tabIndex) &&
        footerClaimActions.length > 0 &&
        !isInquiry && <TaskDetailAction listAction={footerClaimActions} caterogy={caterogy} />}

      {(![TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService].includes(caterogy) ||
        ![
          TaskDetailTab.GENERAL_INFO_TAB,
          TaskDetailTab.SUPPLEMENT_INFO_TAB,
          TaskDetailTab.RELATED_INFO_TAB,
          TaskDetailTab.QC_CHECK_TAB,
          TaskDetailTab.INTERNAL_FEEBACK_TAB
        ].includes(tabIndex)) &&
        roles &&
        !isInquiry &&
        footer}

      {detail && caterogy && basket && (
        <UnsuspendPopup
          open={isUnSuspendPopupOpen}
          onClose={() => setIsUnSuspendPopupOpen(false)}
          onSuccess={onHandleActionSuccess}
          selectedValue={{
            category: caterogy as TaskType,
            basket: basket,
            taskIds: [detail.id],
            updateToCore: updateToCore,
            currAssignee: detail.assignee
          }}
        />
      )}

      {!isHistory && (
        <CompletePopup
          open={completeOpen}
          onClose={() => setCompleteOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail?.id || '-',
            processId: detail?.processId || '-',
            updateToCore: updateToCore,
            policyNum: detail?.primaryPolicyNo || '-',
            category: detail?.category,
            isUWQC: basket === SubTaskType.QCUnderwriting,
            isUWTransaction: isUWTransaction,
            UWDecision: getUWDecision(),
            isUWCase: isUWScreen,
            isCancelPayment: detail?.isCancelPayment,
            pvNumberCashout: detail?.pvNumber || '',
            transaction: detail?.transaction,
            uwResult: detail?.uwResult || '',
            dynamicKeywordData: dynamicKeywordData,
            isSavedDynamicKeyword: isSavedDynamicKeyword,
            isQC: isQC,
          }}
        />
      )}

      {detail && !isHistory && (
        <EndPopup
          open={isEndPopupOpen}
          onClose={() => setIsEndPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail.id,
            updateToCore: updateToCore,
            category: caterogy as string
          }}
        />
      )}

      {detail && !isHistory && caterogy !== TaskType.CustomerService && (
        <RejectPopup
          open={isRejectPopupOpen}
          onClose={() => setIsRejectPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail.id,
            updateToCore: updateToCore,
            processId: detail.processId,
            category: [TaskType.IDC].includes(caterogy) ? TaskType.IDC : undefined,
            dynamicKeywordData: dynamicKeywordData,
            isSavedDynamicKeyword: isSavedDynamicKeyword
          }}
        />
      )}

      {detail && caterogy && basket && (
        <TransferPopup
          open={isTransferPopupOpen}
          onClose={() => setIsTransferPopupOpen(false)}
          onSuccess={onHandleActionSuccess}
          selectedValue={{
            category: caterogy as TaskType,
            basket: basket,
            taskIds: [detail.id],
            updateToCore: updateToCore,
            transactionType: detail.payload?.tag,
            isUWCase: isUWScreen
          }}
        />
      )}

      {detail && planSuspendDate && (
        <SuspendPopup
          open={isSuspendPopupOpen}
          onClose={() => setIsSuspendPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            processId: detail.processId,
            taskId: detail.id,
            planSuspendDate: planSuspendDate,
            updateToCore: updateToCore,
            isCancelPayment: detail?.isCancelPayment,
            firstSuspendDate: detail?.payload?.firstSuspendDate,
            dynamicKeywordData: dynamicKeywordData,
            isSavedDynamicKeyword: isSavedDynamicKeyword
          }}
          category={caterogy}
        />
      )}
      {detail && caterogy && basket && isPCWriteOff && (
        <TaskUnsuspendWriteOffModal
          open={isUnsuspendWriteOffModalVisible}
          onClose={() => setUnsuspendWriteOffModalVisible(false)}
          onSuccess={onHandleActionSuccess}
          selectedValue={{
            category: caterogy as TaskType,
            basket: basket,
            taskIds: [detail.id],
            updateToCore: updateToCore,
            currAssignee: detail.assignee
          }}
        />
      )}
    </View>
  )
}
