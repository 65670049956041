/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions
} from 'react-native'
import {
  AppContext,
  DatePicker,
  SelectOption,
  RBAC,
  ErrorHandling,
  SelectSearch,
  Input,
  assets,
  GroupPolicyPermission,
  MenuPermissions
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import { addMonths, isAfter, startOfDay } from 'date-fns/fp'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import moment from 'moment'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'
import { SEAWithdrawChangeService } from './SEAWithdrawChangeService'
import { SEAWithdrawChangeConst } from './SEAWithdrawChangeConst'
import { SEAWithdrawFailListDetailModal } from './SEAWithdrawFailListDetailModal'
import { SEAWithdrawChangeForm } from './SEAWithdrawChangeForm'

export interface WithdrawDetailItem {
  id: string
  exchangeId: string
  policyNumber: string
  paymentMethod: string
  fromDate: string
  toDate: string
  fundPriorityFirst: string
  fundPrioritySecond: string
  fundPriorityThird: string
  fundPriorityFourth: string
  fundPriorityFifth: string
  fundPrioritySixth: string
  fundPrioritySeventh: string
  functionType: string
  document: string
  importedFile: string
  status: string
  reason: string
  importBy: string
  createdDate: string
  createdBy: string
  createdImportDate: string
  modifiedBy: string
  modifiedDate: string
  flag: string
  amlRegister: string
}

export interface WithdrawImportHistoryDetailFormData {
  fromDate: Date | null
  toDate: Date | null
  policyNumber: string
  status: SelectOption
  functionType: SelectOption
  implementUser: string
  importedFile: string
}
type Props = {
  form: Omit<UseFormReturn<SEAWithdrawChangeForm.Raw>, 'handleSubmit'>
}
export const SEAWithdrawImportHistoryDetailScreen = (props: Props) => {
  let isView = false
  const navigation = useNavigation()
  const { t, i18n } = useTranslation('BillingChange')
  const billingChanceImportHistoryForm = useForm<WithdrawImportHistoryDetailFormData>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      status: {
        label: i18n.language === 'en' ? 'All' : 'Tất cả',
        value: SEABillingChangeModal.Status.ALL
      },
      functionType: {
        label: i18n.language === 'en' ? 'All' : 'Tất cả',
        value: SEABillingChangeModal.Action.ALL
      }
    }
  })
  const [loading, bindLoading] = useLoading()
  const { control, setValue, watch } = billingChanceImportHistoryForm
  const [maxDate] = React.useState<Date>(new Date())
  const [dataSource, setDataSource] = React.useState<WithdrawDetailItem[]>([])
  const isFocused = useIsFocused()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { height } = useWindowDimensions()
  const [openModalViewDetail, setOpenModalViewDetail] = React.useState<boolean>(false)
  const [objectIdDetail, setObjectIdDetail] = React.useState<string | null>(null)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  React.useEffect(() => {
    if (!!props?.form?.watch('importedFileName') && props?.form?.watch('checkReset') === true) {
      setValue('importedFile', props?.form?.watch('importedFileName') ?? '')
      setValue('fromDate', props?.form?.watch('fromDate'))
      setValue('toDate', props?.form?.watch('toDate'))
      onViewReportClick()
    }
  }, [props.form.watch('importedFileName'), props.form.watch('fromDate'), props.form.watch('toDate')])
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('ImportFileForPartialWithdraw'),
          navigate: null
        }
      ])
    }
  }, [isFocused])
  const getQuery = (input: {
    order: string | undefined
    sort: string | undefined
    start: number
    page: number
    size: number | undefined
    policyNumber: string | undefined
    status: SEABillingChangeModal.Status
    functionType: SEABillingChangeModal.Action
    fromCreatedDate: Date
    toCreatedDate: Date
    implementUser: string | undefined
    importedFile: string | undefined
  }) => {
    const {
      policyNumber,
      status,
      functionType,
      fromCreatedDate,
      toCreatedDate,
      implementUser,
      importedFile,
      size,
      start,
      page,
      order,
      sort
    } = input
    return {
      order,
      sort,
      start,
      page,
      size,
      policyNumber,
      status,
      functionType,
      fromCreatedDate: moment(fromCreatedDate).startOf('day').toDate(),
      toCreatedDate: moment(toCreatedDate).endOf('day').toDate(),
      implementUser,
      importedFile
    }
  }
  const dateStrWF = (date: string | null | undefined, format: string): string =>
    !!date && date !== '0' && date !== '99999999' && date != '9999-99-99' && date !== '-'
      ? moment(date, 'DD/MM/YYYY HH:mm:ss').add(7, 'hours').format(format)
      : '-'
  const mappingDataSource = (data: SEABillingChangeModal.ReportWithdrawData, start = 0) => {
    const filteredDataSource = data.map((item, index) => ({
      id: isView === true ? item.id : (start + index + 1).toString(),
      exchangeId:
        item.fundPriorityFirst +
          '; ' +
          item.fundPrioritySecond +
          '; ' +
          item.fundPriorityThird +
          '; ' +
          item.fundPriorityFourth +
          '; ' +
          item.fundPriorityFifth +
          '; ' +
          item.fundPrioritySixth +
          '; ' +
          item.fundPrioritySeventh || '-',
      policyNumber: item.policyNumber || '-',
      paymentMethod:
        item.paymentMethod === 'Nộp phí bảo hiểm định kỳ' ? t('CFI:InstallmentPremium') : item.paymentMethod || '-',
      fromDate: item.fromDate || '-',
      toDate: item.toDate || '-',
      fundPriorityFirst: item.fundPriorityFirst || '-',
      fundPrioritySecond: item.fundPrioritySecond || '-',
      fundPriorityThird: item.fundPriorityThird || '-',
      fundPriorityFourth: item.fundPriorityFourth || '-',
      fundPriorityFifth: item.fundPriorityFifth || '-',
      fundPrioritySixth: item.fundPrioritySixth || '-',
      fundPrioritySeventh: item.fundPrioritySeventh || '-',
      functionType:
        item.functionType === 'N' || item.functionType === 'D'
          ? SEABillingChangeModal.getActionName(item.functionType ?? '')(i18n.language)
          : item.functionType || '',
      document: item.document || '-',
      importedFile: item.importedFile || '-',
      status: item.status || '-',
      reason: item.reason || '-',
      importBy: item.importBy || '-',
      createdDate: dateStrWF(item.createdDate, 'DD/MM/YYYY HH:mm') || '-',
      createdBy: item.createdBy || '-',
      createdImportDate: item.createdImportDate || '-',
      modifiedBy: item.modifiedBy || '-',
      modifiedDate: item.modifiedDate || '-',
      flag: item.flag || '-',
      amlRegister: item.amlRegister || '-',
    }))
    if (isView) {
      setDataSource(filteredDataSource)
    }
    return filteredDataSource
  }

  const handleReportData = async (
    handle: (report: SEABillingChangeModal.ReportsWithdrawData) => ZIO<any, Throwable, any>
  ) => {
    const size = isView ? pageSize : 0
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const selectedstatus = watch('status') || null
    const selectedAction = watch('functionType') || null
    const policyNumber = watch('policyNumber') || null
    const implementUser = watch('implementUser') || null
    const importedFile = watch('importedFile') || null
    const customedStatus = selectedstatus?.value as SEABillingChangeModal.Status
    const customedAction = selectedAction?.value as SEABillingChangeModal.Action
    if (await validateDataFields(customedFromDate, customedToDate)) {
      const query = getQuery({
        order: 'desc',
        sort: 'createdDate',
        start: 0,
        page: 0,
        size,
        policyNumber: policyNumber ?? '',
        status: customedStatus,
        functionType: customedAction,
        fromCreatedDate: customedFromDate,
        toCreatedDate: customedToDate,
        implementUser: implementUser ?? '',
        importedFile: importedFile ?? ''
      })
      showGlobalLoading(true)
      pipe(
        SEAWithdrawChangeService.getReportData(query),
        ZIO.flatMap((report) =>
          pipe(
            O.fromNullable(report.data),
            O.filter((data) => data.length > 0),
            O.fold(
              () => {
                setDataSource([])
                showToast(t('message:MS030029'), 'error')
                showGlobalLoading(false)
                return ZIO.unit
              },
              (data) => {
                if (isView) {
                  mappingDataSource(data)
                }
                showGlobalLoading(false)
                return handle(report)
              }
            )
          )
        ),
        ZIO.catchAll(() => {
          setDataSource([])
          showToast(t('message:MS030029'), 'error')
          showGlobalLoading(false)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const handleReportDataToExport = async (
    handle: (reportData: SEABillingChangeModal.ReportWithdrawData) => ZIO<any, Throwable, any>
  ) => {
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const selectedstatus = watch('status')
    const selectedAction = watch('functionType')
    const policyNumber = watch('policyNumber')
    const implementUser = watch('implementUser')
    const importedFile = watch('importedFile')
    const customedStatus = selectedstatus.value as SEABillingChangeModal.Status
    const customedAction = selectedAction.value as SEABillingChangeModal.Action
    const isDataFieldValid = await validateDataFields(customedFromDate, customedToDate)
    if (isDataFieldValid) {
      const query = getQuery({
        order: 'desc',
        sort: 'createdDate',
        start: 0,
        page: 0,
        size: totalItem,
        policyNumber: policyNumber ?? '',
        status: customedStatus,
        functionType: customedAction,
        fromCreatedDate: customedFromDate,
        toCreatedDate: customedToDate,
        implementUser: implementUser ?? '',
        importedFile: importedFile ?? ''
      })
      const totalItems = await pipe(
        SEAWithdrawChangeService.getReportData(query),
        ZIO.map((response) => {
          return response.data ? response.data?.length : 0
        }),
        ZIO.catchAll((_) => ZIO.succeed(0)),
        ZIO.unsafeRun({})
      )
      if (totalItems > 0) {
        const PAGE_SIZE = 5000
        const totalPages = Math.ceil(totalItems / PAGE_SIZE)

        await pipe(
          ZIO.sequencePar(
            _.times(totalPages, (page) =>
              pipe(
                SEAWithdrawChangeService.getReportData(query),
                ZIO.catchAll((_) => ZIO.succeed({ data: [] })),
                ZIO.map((response) => response?.data || [])
              )
            )
          ),
          ZIO.map(_.flatten),
          ZIO.flatMap((data) => {
            if (data.length === totalItems) {
              return handle(data)
            } else {
              setDataSource([])
              showToast(t('message:MS030029'), 'error')
            }
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        setDataSource([])
        showToast(t('message:MS030029'), 'error')
      }
    }
  }

  const handlePaging = (pageIndex: number, currentpageSize: number) => {
    const start = currentpageSize * pageIndex
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const selectedStatus = watch('status')
    const selectedAction = watch('functionType')
    const policyNumber = watch('policyNumber')
    const implementUser = watch('implementUser')
    const importedFile = watch('importedFile')

    const customedStatus = selectedStatus.value as SEABillingChangeModal.Status
    const customedAction = selectedAction.value as SEABillingChangeModal.Action
    const query = getQuery({
      order: 'desc',
      sort: 'createdDate',
      start,
      page: pageIndex,
      size: currentpageSize,
      policyNumber: policyNumber ?? '',
      status: customedStatus,
      functionType: customedAction,
      fromCreatedDate: customedFromDate,
      toCreatedDate: customedToDate,
      implementUser: implementUser ?? '',
      importedFile: importedFile ?? ''
    })
    showGlobalLoading(true)
    isView = true
    pipe(
      SEAWithdrawChangeService.getReportData(query),
      ZIO.flatMap((report) =>
        pipe(
          O.fromNullable(report.data),
          O.filter((data) => data.length > 0),
          O.fold(
            () => {
              showToast(t('message:MS030029'), 'error')
              setDataSource([])
              showGlobalLoading(false)
              return ZIO.unit
            },
            (data) => {
              mappingDataSource(data, pageIndex)
              showGlobalLoading(false)
              return ZIO.unit
            }
          )
        )
      ),
      ZIO.catchAll(() => {
        showToast(t('message:MS030029'), 'error')
        setDataSource([])
        showGlobalLoading(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const setDefaultValues = () => {
    const currentDate = new Date()
    if (!watch('fromDate') && !watch('toDate')) {
      setValue('fromDate', currentDate)
      setValue('toDate', currentDate)
    }
  }

  const validateDataFields = async (fromDateItem: Date, toDateItem: Date) => {
    const customedFromDate = moment(fromDateItem).format('YYYY/MM/DD')
    const customedToDate = moment(toDateItem).format('YYYY/MM/DD')
    const isValidForm = await billingChanceImportHistoryForm.trigger()
    if (!isValidForm) {
      return false
    } else if (!moment(fromDateItem).isValid()) {
      return false
    } else if (!moment(customedToDate).isValid()) {
      return false
    } else if (fromDateItem && !toDateItem) {
      return false
    } else if (!fromDateItem && toDateItem) {
      return false
    } else if (moment(customedFromDate) > moment(customedToDate)) {
      showToast(t('message:MS030044'), 'error')
      return false
    } else if (
      moment(customedFromDate).valueOf() > moment().valueOf() ||
      moment(customedToDate).valueOf() > moment().valueOf()
    ) {
      return false
    } else if (
      pipe(fromDateItem, startOfDay, addMonths(3), (fromDateAdd3Months) =>
        isAfter(fromDateAdd3Months)(startOfDay(toDateItem))
      )
    ) {
      showToast(t('message:MS080004', { k: 3 }), 'error')
      return false
    }
    return true
  }

  const onViewReportClick = () => {
    isView = true
    setDefaultValues()
    handleReportData((report) => {
      setPageNum(0)
      setTotalItem(report.total)
      return ZIO.unit
    })
  }
  const nameTranslateStatus = (value: string) => {
    if (value === 'Success') {
      return i18n.language === 'en' ? 'Success' : 'Thành công'
    } else if (value === 'Fail') {
      return i18n.language === 'en' ? 'Fail' : 'Thất bại'
    } else if (value === 'Time_out') {
      return i18n.language === 'en' ? 'Time_out' : 'Quá hạn'
    } else if (value === 'Duplicate') {
      return i18n.language === 'en' ? 'Duplicate' : 'Trùng tập tin'
    } else {
      return '-'
    }
  }

  const onExcelExport = async () => {
    showGlobalLoading(true)
    isView = false
    setDefaultValues()
    await handleReportDataToExport((reportData) => {
      const customedFromDate = watch('fromDate') as Date
      const customedToDate = watch('toDate') as Date
      const selectedstatus = watch('status')
      const selectedAction = watch('functionType')
      const namestatus = selectedstatus.label ?? '-'
      const nameAction = selectedAction.label ?? '-'
      const summary = {
        fromDate: customedFromDate,
        toDate: customedToDate,
        title: '',
        status: namestatus,
        functionType: nameAction
      }
      const columns = SEAWithdrawChangeConst.displayedColumns.map(({ name, widthPx, horizontal }) => ({
        name: t(name),
        horizontal,
        width: widthPx
      }))
      const exportDataSource = mappingDataSource(reportData)
      const rows = exportDataSource.map(
        ({
          id,
          policyNumber,
          paymentMethod,
          exchangeId,
          fromDate,
          toDate,
          createdDate,
          functionType,
          importedFile,
          status,
          document,
          reason,
          importBy,
          // amlRegister
        }) => [
          id,
          policyNumber,
          paymentMethod,
          exchangeId,
          fromDate,
          toDate,
          createdDate,
          functionType,
          importedFile,
          nameTranslateStatus(status),
          document,
          SEABillingChangeModal.getFailReasonWithdrawName(reason)(i18n.language),
          importBy,
          // amlRegister
        ]
      )
      isView = true
      return SEAWithdrawChangeService.exportTemplate({ ref: 'B8', colRef: 2, rowRef: 8 }, summary, columns, rows)
    })
    showGlobalLoading(false)
  }

  const colorStatus = (color: string) => {
    if (color === 'Success') {
      return '#3cb1fd'
    } else if (color === 'Fail') {
      return 'red'
    } else if (color === 'Time_out') {
      return '#feaf74'
    } else if (color === 'Duplicate') {
      return '#D3DCE6'
    } else if (color === 'Error') {
      return 'red'
    } else if (color === 'Waiting') {
      return '#FF6F00'
    } else {
      return '#ffff'
    }
  }

  const handleViewDetail = (objectId: string) => {
    setObjectIdDetail(objectId)
    setOpenModalViewDetail(true)
  }
  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#D3DCE6',
            margin: 15,
            padding: 25
          }}
        >
          <View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <Controller
                  name="fromDate"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={[{ marginHorizontal: 15, marginBottom: 15, width: '70%' }, { marginTop: 6 }]}>
                      <DatePicker
                        keyboardIconRed={true}
                        label={t('common:FromDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={value ? '' : error?.message}
                        maxDate={maxDate}
                        // minDate={moment(watch().toDate).subtract(30, 'days')}
                        alwaysShow={true}
                      />
                    </View>
                  )}
                />
              </View>

              <View style={{ flex: 1, alignItems: 'flex-start' }}>
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={[{ marginHorizontal: 15, marginBottom: 15, width: '70%' }, { marginTop: 6 }]}>
                      <DatePicker
                        keyboardIconRed={true}
                        label={t('common:ToDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={value ? '' : error?.message}
                        maxDate={maxDate}
                        alwaysShow={true}
                      />
                    </View>
                  )}
                />
              </View>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <Controller
                  name="implementUser"
                  control={control}
                  render={({ field }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 15, width: '70%' }}>
                      <Input {...field} value={field.value ?? ''} title={t('ImplementUser')} />
                    </View>
                  )}
                />
              </View>
              <View style={{ flex: 1, alignItems: 'flex-start' }}>
                <Controller
                  name="importedFile"
                  control={control}
                  render={({ field }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 15, width: '70%' }}>
                      <Input {...field} value={field.value ?? ''} title={t('ImportedFileName')} />
                    </View>
                  )}
                />
              </View>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <Controller
                  name="policyNumber"
                  control={control}
                  render={({ field }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 20, width: '70%' }}>
                      <Input
                        {...field}
                        value={field.value ?? ''}
                        title={t('Policy')}
                        maxLength={9}
                        inputType={'number'}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ flex: 1, alignItems: 'flex-start' }}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 15, width: '70%' }}>
                      <SelectSearch
                        label={t('Status')}
                        options={
                          i18n.language === 'en' ? SEAWithdrawChangeConst.Status : SEAWithdrawChangeConst.StatusVi
                        }
                        placeholder={t('common:Select')}
                        onChange={(val) => {
                          onChange(val)
                        }}
                        onBlur={() => {
                          onChange(
                            (!value || !value.value) && {
                              label: i18n.language === 'en' ? 'All' : 'Tất cả',
                              value: 'ALL'
                            }
                          )
                        }}
                        value={value}
                        labelStyle={{ marginBottom: 8 }}
                        errorMessage={error?.message}
                        required={false}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                      />
                    </View>
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <Controller
                  name="functionType"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 15, width: '70%' }}>
                      <SelectSearch
                        label={t('Action')}
                        options={
                          i18n.language === 'en' ? SEAWithdrawChangeConst.ActionEn : SEAWithdrawChangeConst.ActionVi
                        }
                        placeholder={t('Action')}
                        onChange={(val) => {
                          onChange(val)
                        }}
                        onBlur={() => {
                          onChange(
                            (!value || !value.value) && {
                              label: i18n.language === 'en' ? 'All' : 'Tất cả',
                              value: 'ALL'
                            }
                          )
                        }}
                        value={value}
                        labelStyle={{ marginBottom: 8 }}
                        errorMessage={error?.message}
                        required={false}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ flex: 1, alignItems: 'flex-start' }}></View>
            </View>
          </View>

          <View
            style={[
              SUREStyle.sectionRow,
              SUREStyle.secondLine,
              { justifyContent: 'center', alignItems: 'center', alignSelf: 'center', padding: 20 }
            ]}
          >
            <View
              style={[
                SUREStyle.col_4,
                { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }
              ]}
            >
              {permissions &&
                (permissions.includes(GroupPolicyPermission.ViewHistoryImportFileDetailAutoDebit) ||
                  permissions.includes(GroupPolicyPermission.SearchHistoryImportFileDetailAutoDebit)) && (
                  <TouchableOpacity style={{}} onPress={onViewReportClick}>
                    <View style={[SUREStyle.primaryBtn, { width: i18n.language === 'vi' ? 150 : 120, height: 40 }]}>
                      <assets.SearchTaskIcon />
                      <View style={{ width: 10 }} />
                      <Text style={{ textAlign: 'center', color: '#fff', fontWeight: '600' }}>{t('Search')}</Text>
                    </View>
                  </TouchableOpacity>
                )}

              <View style={{ width: 25 }} />
              {permissions &&
                (permissions.includes(GroupPolicyPermission.ExportHistoryImportFileAutoDebit) ||
                  permissions.includes(GroupPolicyPermission.ExportHistoryImportFileDetailAutoDebit)) && (
                  <TouchableOpacity style={{}} onPress={onExcelExport}>
                    <View style={[SUREStyle.primaryBtn, { width: i18n.language === 'vi' ? 150 : 120, height: 40 }]}>
                      <View
                        style={{
                          width: 20,
                          height: 22,
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'center'
                        }}
                      >
                        <assets.ExportCurve />
                      </View>
                      <View style={{ width: 10 }} />
                      <Text style={{ textAlign: 'center', color: '#fff', fontWeight: '600' }}>{t('Export')}</Text>
                    </View>
                  </TouchableOpacity>
                )}
            </View>
          </View>
        </View>
        {dataSource && dataSource.length > 0 ? (
          <View style={{ padding: 20 }}>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableCellHeader} style={{ width: 100 }}>
                      {t('Policy')}
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Payoutmethod')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Fundlist')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Fromday')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Todate')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('ImportDate')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Action')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('ImportedFileName')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Status')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Consentname')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('Failreason')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('UserImplement')}</TableCell>
                    {/* <TableCell className={classes.tableCellHeader}>{t('AMLRegister')}</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSource.map((item, index) => {
                    return (
                      <TableRow key={1}>
                        <TableCell className={classes.tableCell}>
                          <Pressable
                            disabled={item.reason === 'MS990119' ? true : false}
                            onPress={() => {
                              handleViewDetail(item?.id ?? '')
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: '600',
                                fontSize: 15,
                                color: item.reason === 'MS990119' ? '#000' : '#1ea5fc',
                                textDecorationLine: 'none'
                              }}
                            >
                              {item.policyNumber || '-'}
                            </Text>
                          </Pressable>
                        </TableCell>

                        <TableCell className={classes.tableCell}>{item.paymentMethod}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.fundPriorityFirst}
                          {'; '}
                          {item.fundPrioritySecond}
                          {'; '}
                          {item.fundPriorityThird}
                          {'; '}
                          {item.fundPriorityFourth}
                          {'; '}
                          {item.fundPriorityFifth}
                          {'; '}
                          {item.fundPrioritySixth}
                          {'; '}
                          {item.fundPrioritySeventh}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.fromDate}</TableCell>
                        <TableCell className={classes.tableCell}>{item.toDate}</TableCell>
                        <TableCell className={classes.tableCell}>{item.createdDate}</TableCell>
                        <TableCell className={classes.tableCell}>{item.functionType}</TableCell>
                        <TableCell className={classes.tableCell}>{item.importedFile || '-'}</TableCell>
                        <TableCell className={classes.tableCell} style={{ color: colorStatus(item.status) }}>
                          {SEABillingChangeModal.getStatusName(item.status)(i18n.language)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.document}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {SEABillingChangeModal.getFailReasonWithdrawName(item.reason)(i18n.language)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.importBy}</TableCell>
                        {/* <TableCell className={classes.tableCell}>{item.amlRegister}</TableCell> */}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                handlePaging(page, pageSize)
              }}
              onRowsPerPageChange={(e) => {
                const rowsPerPage = Number(e.target.value)
                setPageSize(rowsPerPage)
                if (pageNum * rowsPerPage >= totalItem) {
                  setPageNum(0)
                  handlePaging(0, rowsPerPage)
                } else {
                  handlePaging(pageNum, rowsPerPage)
                }
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            ></TablePagination>
          </View>
        ) : (
          !loading && (
            <View style={{ marginHorizontal: 30 }}>
              <Text style={{ color: '#ED1B2E' }}>{t('message:MS030029')}</Text>
            </View>
          )
        )}
      </ScrollView>
      {objectIdDetail && (
        <SEAWithdrawFailListDetailModal
          visible={openModalViewDetail}
          onClose={(visible) => {
            setOpenModalViewDetail(visible)
            setObjectIdDetail(null)
          }}
          idDetial={objectIdDetail || ''}
        />
      )}
    </SafeAreaView>
  )
}

const SUREStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 30
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    flexDirection: 'row',
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    justifyContent: 'center'
  },
  cellWidth200: {
    minWidth: '200px'
  }
})
